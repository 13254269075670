import {
  TripadvisorRating,
  GoogleRating,
  TripadvisorRatingProps,
  GoogleRatingProps,
  HolidayCheckRating,
  HolidayCheckRatingProps,
  DontHydrate,
  ModalHeader,
} from '@loveholidays/design-system';
import React, { Fragment } from 'react';
import { SxStyleProp } from 'theme-ui';

import { RatingProvider } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { GoogleReviews, GoogleReviewsProps } from '@Components/HotelDetails/GoogleReviews';
import {
  TripAdvisorReviews,
  TripAdvisorReviewsProps,
} from '@Components/HotelDetails/TripAdvisorReviews';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

type RatingProps = TripadvisorRatingProps & GoogleRatingProps & HolidayCheckRatingProps;
type ReviewsComponentProps = TripAdvisorReviewsProps | GoogleReviewsProps;

interface RatingLinkConfig extends ClassNameProps, RatingProps {
  provider: RatingProvider;
  reviewProviderId?: number;
  size?: 'default' | 'large';
  trackingAction?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  reviewComponentProps?: ReviewsComponentProps;
}

interface RatingLinkStuff {
  component: React.FC<RatingProps>;
  label: string;
  reviewsComponent: React.FC<ReviewsComponentProps>;
  hoverEffect?: SxStyleProp;
}

const RatingMap: Record<RatingLinkConfig['provider'], RatingLinkStuff> = {
  google: {
    component: GoogleRating,
    label: 'google-link',
    reviewsComponent: GoogleReviews as React.FC<ReviewsComponentProps>,
  },
  tripadvisor: {
    component: TripadvisorRating,
    label: 'trip-advisor-link',
    reviewsComponent: TripAdvisorReviews as React.FC<ReviewsComponentProps>,
  },
  holidaycheck: {
    component: HolidayCheckRating,
    label: 'holidaycheck-link',
    reviewsComponent: TripAdvisorReviews as React.FC<ReviewsComponentProps>,
  },
};

export const RatingLink: React.FC<RatingLinkConfig> = ({
  rating = 0,
  reviewCount = 0,
  recommendation,
  className,
  provider,
  onClick,
  trackingAction,
  size = 'default',
  floating = true,
  variant,
  reviewComponentProps,
  display,
}) => {
  const [isOpen, setModalOpen, setModalClose] = useModal();

  if (!rating) {
    return null;
  }

  const config = RatingMap[provider];

  return (
    <Fragment>
      <WithClickTracking
        action={trackingAction || config.label}
        label={config.label}
      >
        <DontHydrate
          as="button"
          data-id={config.label}
          type="button"
          onClick={onClick || setModalOpen}
          className={className}
          sx={{
            textAlign: 'left',
            lineHeight: 1,
          }}
        >
          <config.component
            reviewCount={reviewCount}
            rating={rating}
            recommendation={recommendation}
            size={size}
            floating={floating}
            variant={variant}
            display={display}
            sx={{
              ...config.hoverEffect,
              borderRadius: variant === 'stacked' ? '12' : 'rounded',
            }}
          />
        </DontHydrate>
      </WithClickTracking>

      {!!reviewComponentProps && isOpen && (
        <LoadableModal
          onClose={setModalClose}
          isFullScreen
          noPadding
          show
          width="fullcontentmaxwidth"
          sx={{
            maxWidth: '1200px',
          }}
          Header={
            <ModalHeader
              layout="medium"
              onClose={setModalClose}
            />
          }
          Content={<config.reviewsComponent {...reviewComponentProps} />}
        />
      )}
    </Fragment>
  );
};
